import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import {
  SET_ORG_PROPERTIES_LOADING,
  SET_ORG_PROPERTIES_SUCCESS,
  SET_ORG_PROPERTIES_ERROR,
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_ERROR,
  CREATE_PROPERTY_LOADING,
  EDIT_PROPERTY_SUCCESS,
  EDIT_PROPERTY_ERROR,
  DELETE_PROPERTY_BY_ID_LOADING,
  DELETE_PROPERTY_BY_ID_SUCCESS,
  DELETE_PROPERTY_BY_ID_ERROR,
  SET_PROPERTY_BY_ID_LOADING,
  SET_PROPERTY_BY_ID_SUCCESS,
  SET_PROPERTY_BY_ID_ERROR,
  FETCH_PROPERTY_BY_ID_ERROR,
  FETCH_PROPERTY_BY_ID_SUCCESS,
  FETCH_PROPERTY_BY_ID_LOADING
} from '../actions/types';

let initialState = {
  data: {
    entities: {},
    result: []
  },
  propertyDetail: {
    entities: {},
    result: []
  },
  loading: false,
  error: null,
  propertyLoading: false,
  propertyError: null,
  createEditPropertyError: null,
  createEditPropertySuccess: false,
  createEditPropertyLoading: false,
  deletePropertyLoading: false,
  deletePropertyError: null,
  deletePropertySuccess: false
};

export const propertiesList = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORG_PROPERTIES_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_ORG_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: { ...action.payload }
      };
    case SET_ORG_PROPERTIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_PROPERTY_BY_ID_LOADING:
      return {
        ...state,
        propertyLoading: true,
        propertyError: null
      };
    case FETCH_PROPERTY_BY_ID_SUCCESS:
      return {
        ...state,
        propertyLoading: false,
        propertyError: null,
        propertyDetail: {
          entities: {
            properties: {
              ...state.propertyDetail.entities.properties,
              ...action.payload.entities.properties
            }
          },
          result: uniq([...state.propertyDetail.result, action.payload.result])
        }
      };
    case FETCH_PROPERTY_BY_ID_ERROR:
      return {
        ...state,
        propertyLoading: false,
        propertyError: action.error
      };
    case CREATE_PROPERTY_LOADING:
      return {
        ...state,
        createEditPropertyLoading: true,
        createEditPropertySuccess: false,
        createEditPropertyError: null
      };
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        createEditPropertyLoading: false,
        createEditPropertySuccess: true,
        createEditPropertyError: null,
        data: {
          entities: {
            properties: { ...state.data.entities.properties, ...action.payload.entities.properties }
          },
          result: [...state.data.result, action.payload.result]
        }
      };
    case CREATE_PROPERTY_ERROR:
      return {
        ...state,
        createEditPropertyError: action.error,
        createEditPropertySuccess: false,
        createEditPropertyLoading: false
      };
    case EDIT_PROPERTY_SUCCESS:
      let editedProperties = omit(state.data.entities.properties, [action.payload.id]);
      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            properties: { ...editedProperties, [action.payload.id]: action.payload }
          }
        },
        createEditPropertyLoading: false,
        createEditPropertySuccess: true
      };
    case EDIT_PROPERTY_ERROR:
      return {
        ...state,
        createEditPropertyError: action.error,
        createEditPropertySuccess: false,
        createEditPropertyLoading: false
      };
    case DELETE_PROPERTY_BY_ID_LOADING:
      return {
        ...state,
        deletePropertyLoading: true
      };
    case DELETE_PROPERTY_BY_ID_SUCCESS:
      let deletedProperties = omit(state.data.entities.properties, [action.payload.id]);
      let result = state.data.result.filter((id) => id !== action.payload.id);
      return {
        ...state,
        deletePropertyLoading: false,
        deletePropertyError: null,
        deletePropertySucces: true,
        data: {
          entities: { properties: deletedProperties },
          result: result
        }
      };
    case DELETE_PROPERTY_BY_ID_ERROR:
      return {
        ...state,
        deletePropertyLoading: false,
        deletePropertyError: action.error,
        deletePropertySucces: false
      };
    case SET_PROPERTY_BY_ID_SUCCESS:
      let updatedProperties = omit(state.data.entities.properties, [action.payload.result]);
      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            properties: {
              ...updatedProperties,
              [action.payload.result]: action.payload.entities.properties[action.payload.result]
            }
          }
        },
        updatePropertyLoading: false,
        updatePropertySuccess: true
      };
    case SET_PROPERTY_BY_ID_LOADING:
      return {
        ...state,
        updatePropertyLoading: true,
        updatePropertyError: null
      };
    case SET_PROPERTY_BY_ID_ERROR:
      return {
        ...state,
        updatePropertyLoading: false,
        updatePropertySuccess: false,
        updatePropertyError: action.error
      };
    default:
      return state;
  }
};
