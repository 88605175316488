import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { InfoCarouselModal } from './InfoCarouselModal';
import { updateProfileSettings } from '../../actions/profileSettings';
import { isUK } from '../../utils/common-methods';
import { iff } from '../../utils/iff';
import { getallOrgsList, isOrgListSuccesss } from '../../selectors/organizationListSelector';
import { createOrgList } from '../../actions/orgsList';

const InfoCarouselWrapper = (props) => {
  const { t } = useTranslation();
  const [isNewSignedupUser, setIsSignedupUser] = useState(false);

  useEffect(() => {
    setIsSignedupUser(props.orgsList.length < 1);
  }, [props.orgsList]);

  const carouselJson = {
    btnText: t('Next'),
    btnBack: t('Back'),
    lastBtnText: iff(props.user.auto_created_org_id, t('Finish'), t('Create Property')),
    onBtnClicked: () => null,
    children: [
      {
        image: 0,
        id: 'Welcome',
        title: iff(isUK(), t('Welcome'), t('Welcome to Cropwise!')),
        description: t(
          `Now you can use technology and innovation in farming. Let’s learn some basics before you start.`
        )
      },
      {
        image: 1,
        id: 'Organization',
        title: t('Organization'),
        description: t(
          'When creating an organization you can manage all of your products, users, properties and seasons.'
        )
      },
      {
        image: 2,
        id: 'Properties',
        title: t('Properties'),
        description: t(
          'A digital representation of your farm, where you can register, define and manage the regions and areas of your property.'
        )
      },
      {
        image: 3,
        id: 'Season',
        title: t('Season'),
        description: t(
          'Easily set up planting and harvest dates to create a season. Associate it with specific fields of your farm.'
        )
      },
      {
        image: 4,
        id: 'Solutions',
        title: iff(isUK(), t('Solutions'), t('Solutions Cropwise')),
        description: t(
          `Now that you've learned the basics, start using our custom solutions to improve your work!`
        )
      }
    ]
  };
  return (
    <InfoCarouselModal
      carouselJson={carouselJson}
      user={props.user}
      updateProfileSettings={props.updateProfileSettings}
      isNewSignedupUser={isNewSignedupUser}
      createOrg={props.createOrg}
    />
  );
};

InfoCarouselWrapper.propTypes = {
  user: PropTypes.object,
  orgsList: PropTypes.arrayOf(PropTypes.object),
  createOrg: PropTypes.func,
  isOrgListSuccesss: PropTypes.bool,
  updateProfileSettings: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    orgsList: getallOrgsList(state),
    isOrgListSuccesss: isOrgListSuccesss(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfileSettings: (userDetails, userId) =>
    dispatch(updateProfileSettings(userDetails, userId)),
  createOrg: (org) => dispatch(createOrgList(org))
});

export const InfoCarouselContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoCarouselWrapper);
