import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { iff } from '../../utils/iff';

const IconComponent = ({ size, name, color, extraClassName, style = {} }) => {
  const StyledIcon = styled.span`
    &::before {
      color: ${color || ''};
      font-size: ${iff(size !== undefined && size !== null && size !== '', `${size}px`, 'inherit')};
    }
  `;

  return (
    <>
      {iff(
        name !== null && name !== undefined && name !== '',
        <StyledIcon
          style={{ ...style }}
          className={`${`icon-${name} `}${iff(
            extraClassName !== null && extraClassName !== undefined && extraClassName !== '',
            ` icon-${extraClassName}`,
            ''
          )}`}
        />,
        <StyledIcon
          style={{ ...style }}
          className={`icon-phenology-1 ${iff(
            extraClassName !== null && extraClassName !== undefined && extraClassName !== '',
            ` icon-${extraClassName}`,
            ''
          )}`}
        />
      )}
    </>
  );
};

IconComponent.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  style: PropTypes.object,
  extraClassName: PropTypes.string
};

export const FontIcon = memo(IconComponent);
